var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center flex-wrap"},[_c('strong',[_vm._v(_vm._s(_vm.purchase.id)+" ")]),_c('SMessageOrderStatus',{staticClass:"ml-1",attrs:{"id":_vm.purchase.status_id,"store":"order.purchase_statuses"}}),_c('TSpinner',{attrs:{"loading":_vm.loading}}),_c('div',{staticClass:"ml-auto"},[_vm._l((_vm.purchase.steps_back),function(status){return _c('SButtonStatus',{key:status.id,attrs:{"id":status,"store":"order.purchase_statuses","disabled":_vm.updating},on:{"click":function($event){return _vm.updatePurchase('previous_status', status)}}})}),(!_vm.lodash.isEmpty(_vm.purchase.steps_back))?_c('CIcon',{staticClass:"ml-2 mr-1",staticStyle:{"transform":"rotate(90deg)"},attrs:{"name":"cil-action-undo"}}):_vm._e(),_vm._l((_vm.purchase.steps_forward),function(status){return _c('SButtonStatus',{key:status.id,attrs:{"id":status,"store":"order.purchase_statuses","disabled":_vm.updating},on:{"click":function($event){return _vm.updatePurchase('next_status', status)}}})}),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),(_vm.purchase.unsolved && _vm.purchase.status_id == 'Debited')?_c('SButtonDebit',{staticClass:"mr-1",attrs:{"options":{ disabled: _vm.updating }},on:{"click":_vm.debit}}):_vm._e(),_c('TButton',{attrs:{"content":"Export excel","icon":"cid-cloud-download","size":"sm"},on:{"click":_vm.exportExcel}}),(_vm.purchaseItems.length)?_c('TButton',{staticClass:"ml-1",attrs:{"icon":"cilCalendarCheck","size":"sm","color":"info","variant":"outline","tooltip":"Schedule change"},on:{"click":function($event){_vm.showEditDates = true}}}):_vm._e(),_c('TButtonRefresh',{staticClass:"ml-1",attrs:{"options":{ disabled: _vm.loading }},on:{"click":function($event){_vm.$store.dispatch('order.order_purchase.detail.fetch', _vm.id);
          _vm.$store.dispatch('order.purchase_items.fetch');}}})],2)],1),_c('CCardBody',[_c('CRow',{staticClass:"mb-4"},[_c('CCol',{staticClass:"mb-3",attrs:{"sm":"12","md":"6","lg":"4"}},[_c('TTableAsForm',{attrs:{"title":"Details","data":_vm.purchase,"fields":_vm.detailFields},scopedSlots:_vm._u([{key:"id",fn:function(ref){
          var value = ref.value;
return [_c('TMessage',{attrs:{"content":value,"noTranslate":"","bold":""}})]}},{key:"supplier_id",fn:function(ref){
          var value = ref.value;
          var data = ref.data;
return [(data.supplier)?_c('SMessageSupplier',{attrs:{"to":_vm.lodash.getReferenceLink('supplier',_vm.lodash.toString(value) ),"content":data.supplier.name,"id":data.supplier_id,"editable":!_vm.updating},on:{"change":function($event){_vm.updatePurchase('supplier_id', $event).then(function () {
                  _vm.purchaseRefresh();
                })}}}):_c('TMessageNotFound',{attrs:{"slug":value}})]}},{key:"buyer_id",fn:function(ref){
                var value = ref.value;
return [(_vm.purchase.buyer)?_c('TLink',{attrs:{"to":_vm.lodash.getReferenceLink('user', value),"content":_vm.purchase.buyer.name,"messageOptions":{ truncate: 1 }}}):_vm._e()]}},{key:"organization_id",fn:function(ref){
                var value = ref.value;
return [_c('SMessageOrganization',{attrs:{"id":value}})]}},{key:"created_at",fn:function(ref){
                var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"small":""}})]}},{key:"updated_at",fn:function(ref){
                var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"small":""}})]}},{key:"debited_at",fn:function(){return [(_vm.debited_at)?_c('TMessageDateTime',{attrs:{"content":_vm.debited_at,"small":"","dateOnly":""}}):_vm._e()]},proxy:true}])})],1),_c('CCol',{staticClass:"mb-3",attrs:{"sm":"12","md":"6","lg":"4"}},[_c('TTableAsForm',{attrs:{"title":"Purchase cost","data":_vm.purchase,"fields":_vm.costOfGoodsFields},scopedSlots:_vm._u([{key:"balance",fn:function(ref){
                var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.purchase.currency_id}})]}},{key:"sub_total",fn:function(ref){
                var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.purchase.currency_id}})]}},{key:"paid",fn:function(ref){
                var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.purchase.currency_id}})]}},{key:"outstanding",fn:function(ref){
                var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.purchase.currency_id}})]}},{key:"unsolved",fn:function(ref){
                var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.purchase.currency_id}})]}},{key:"debited",fn:function(ref){
                var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.purchase.currency_id}})]}}])})],1),_c('CCol',{staticClass:"mb-3",attrs:{"sm":"12","md":"6","lg":"4"}},[_c('TTableAsForm',{attrs:{"title":"Other fees","data":_vm.purchase,"fields":_vm.costFields},scopedSlots:_vm._u([{key:"additional_cost",fn:function(ref){
                var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"editable":!_vm.updating,"currency":_vm.purchase.currency_id},on:{"change":function($event){return _vm.updatePurchase('additional_cost', $event)}}})]}},{key:"compensation",fn:function(ref){
                var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.purchase.currency_id}})]}}])})],1),_c('CCol',{staticClass:"mb-3 mt-2"},[_c('Attachments',{attrs:{"purchase_id":_vm.id}})],1)],1),_c('CRow',{staticClass:"mb-4"},[_c('CCol',[_c('TTableAdvance',{attrs:{"items":_vm.purchaseItems,"fields":_vm.itemFields,"store":"order.purchase_items","resource":"purchase/purchases","title":"Items","noFilter":"","removable":"","creatable":""},on:{"click-create":function($event){_vm.showCreateItem = true}},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
                var item = ref.item;
return [_c('CCol',{staticClass:"px-1 text-center",attrs:{"col":"12"}},[_c('SButtonDistribution',{on:{"click":function($event){return _vm.clickDistribution(item.id)}}})],1)]}},{key:"product",fn:function(ref){
                var item = ref.item;
return [_c('td',[(item)?_c('SCardProductItems',{attrs:{"item":item.product,"widthAuto":"","resource":"/purchase/goods/products"}}):_vm._e()],1)]}},{key:"price",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"decimalLimit":6,"amount":item.price,"editable":!_vm.purchaseItemLoading,"currency":_vm.purchase.currency_id},on:{"change":function($event){return _vm.updatePurchaseItem('price', $event)}}})],1)]}},{key:"price_with_tax",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.price_with_tax,"currency":_vm.purchase.currency_id}})],1)]}},{key:"quantity",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TTableAsForm',{staticStyle:{"min-width":"250px","font-size":"15px"},attrs:{"data":item,"fields":[
                  { key: 'quantity', label: 'Purchase' },
                  {
                    key: 'distributed_quantity',
                    label: 'Distributed',
                  },
                  {
                    key: 'remaining_distributed_quantity',
                    label: 'Remaining',
                  },
                  {
                    key: 'received_quantity',
                    label: 'Received at the warehouse',
                  } ],"splitLeft":6,"addRowClasses":['']},scopedSlots:_vm._u([{key:"quantity",fn:function(ref){
                var value = ref.value;
return [_c('TMessageNumber',{attrs:{"value":value,"editable":!_vm.purchaseItemLoading},on:{"change":function($event){return _vm.updatePurchaseItem('quantity', $event)}}})]}},{key:"distributed_quantity",fn:function(ref){
                var value = ref.value;
return [_c('TMessageNumber',{attrs:{"value":value}})]}},{key:"remaining_distributed_quantity",fn:function(ref){
                var value = ref.value;
return [_c('TMessageNumber',{attrs:{"value":value}})]}},{key:"received_quantity",fn:function(ref){
                var value = ref.value;
return [_c('TMessageNumber',{attrs:{"value":value}})]}}],null,true)})],1)]}},{key:"tax_percent",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TMessagePercent',{attrs:{"value":item.tax_percent,"editable":!_vm.purchaseItemLoading},on:{"change":function($event){return _vm.updatePurchaseItem('tax_percent', $event)}}})],1)]}},{key:"amount",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.amount,"currency":_vm.purchase.currency_id}})],1)]}},{key:"tax",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.tax,"currency":_vm.purchase.currency_id}})],1)]}},{key:"balance",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.balance,"currency":_vm.purchase.currency_id}})],1)]}},{key:"date",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TTableAsForm',{attrs:{"data":item,"fields":[
                  { key: 'payment_due_date', label: 'Payment due date' },
                  {
                    key: 'expected_delivery',
                    label: 'Expected delivery',
                  } ],"splitLeft":6,"addRowClasses":['pb-1']},scopedSlots:_vm._u([{key:"payment_due_date",fn:function(){return [_c('TMessageDateTime',{attrs:{"dateOnly":"","showEditModal":"","content":item.payment_due_date,"editable":!_vm.purchaseItemLoading},on:{"change":function($event){return _vm.updatePurchaseItem('payment_due_date', $event)}}})]},proxy:true},{key:"expected_delivery",fn:function(){return [_c('TMessageDateTime',{attrs:{"dateOnly":"","showEditModal":"","content":item.expected_delivery,"editable":!_vm.purchaseItemLoading},on:{"change":function($event){return _vm.updatePurchaseItem('expected_delivery', $event)}}})]},proxy:true}],null,true)})],1)]}},{key:"note",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TMessageText',{attrs:{"value":item.note,"editable":!_vm.purchaseItemLoading,"messageOptions":{ truncate: 3 }},on:{"change":function($event){return _vm.updatePurchaseItem('note', $event)}}})],1)]}}])}),_c('AddItem',{key:_vm.key,attrs:{"show":_vm.showCreateItem,"currency_id":_vm.purchase.currency_id},on:{"update:show":function($event){_vm.showCreateItem=$event},"click-add":_vm.addItem}}),(!_vm.lodash.isEmpty(_vm.purchase))?_c('Distribution',{attrs:{"show":_vm.showDistribution,"purchase_item":_vm.id,"organization_id":_vm.purchase.organization_id},on:{"update:show":function($event){_vm.showDistribution=$event}}}):_vm._e(),_c('ChangeDates',{attrs:{"items":_vm.purchaseItems,"show":_vm.showEditDates},on:{"update:show":function($event){_vm.showEditDates=$event}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }